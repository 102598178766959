<template>
  <v-container style="margin:0px; width:100vw;max-width:100vw" ref="Carrito">
    <v-row><div style="height:50px"></div></v-row>
    <v-row class="text-center">
      <v-col cols="12" style="padding:0px;display:flex;flex-direction:column">
        <div
          :style="
            'width:100vw;padding:15px;margin-bottom:40px;color:white;background-color:' +
              colors[1]
          "
        >
          <span
            style="letter-spacing: 5px;font-family: Calibri;font-size:1.5rem;font-weight:bolder"
            >{{ carrito_num != 0 ? "CARRITO" : "CARRITO VACÍO" }}</span
          >
        </div>
        <div
          style="align-self:center;width:90vw;max-width:800px;"
          v-if="carrito_num != 0"
        >
          <div v-if="productos.length != 0">
            <span
              style="letter-spacing: 5px;font-family: Calibri;font-size:1.25rem;font-weight:bolder;"
            >
              PRODUCTOS
            </span>
            <v-data-table
              mobile-breakpoint="0"
              hide-default-footer
              :headers="headersP"
              :items="productos"
              class="elevation-1 text-center"
            >
              <template v-slot:item.precio="{ item }">
                <span>
                  $
                  {{ parseFloat(item.precio).toFixed(2) }}
                </span>
              </template>
              <template v-slot:item.total="{ item }">
                <span>
                  $
                  {{
                    parseFloat(
                      parseFloat(item.precio) * parseFloat(item.cantidad)
                    ).toFixed(2)
                  }}
                </span>
              </template>
              <template v-slot:item.actions="{ item }">
                <v-icon small @click="deleteProducto(item)">
                  mdi-delete
                </v-icon>
              </template>
              <template v-slot:body.append>
                <tr>
                  <td colspan="4" style="text-align:end">
                    <b>TOTAL:</b>
                  </td>
                  <td>
                    <b> $ {{ parseFloat(calculaTotal("P")).toFixed(2) }} </b>
                  </td>
                  <td></td>
                </tr>
              </template>
            </v-data-table>
          </div>

          <div style="margin-top:20px">
            <v-card>
              <v-card-title
                class="text-body-1 font-weight-bold"
                :style="
                  'max-height:50px;color:white;background-color:' + colors[0]
                "
              >
                <div
                  style="margin-top:-10px;width:100%;display:flex;align-items:center"
                >
                  AGREGAR CUPÓN
                  <v-btn
                    @click="showCupon = !showCupon"
                    icon
                    dark
                    style="margin-left:auto"
                    ><v-icon>{{ showCupon ? "mdi-minus" : "mdi-plus" }}</v-icon>
                  </v-btn>
                </div>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text v-if="showCupon" style="height:100%">
                <div style="font-size:15px;margin-bottom:5px">
                  <b>Código:</b>
                  <v-chip
                    dark
                    :color="colors[1]"
                    style="font-size:18px;font-weight:bolder;margin-left:7px"
                  >
                    <v-text-field
                      v-model="codigo_cupon"
                      label="Escriba Aquí el Código"
                      rounded
                      clearable
                      :disabled="cupon.descripcion != undefined"
                    ></v-text-field>
                  </v-chip>
                  <v-btn
                    v-if="cupon.descripcion == undefined"
                    @click="validaCodigo"
                    icon
                    :color="colors[0]"
                    ><v-icon>mdi-reload</v-icon>
                  </v-btn>
                  <v-btn v-else @click="cupon = {}" icon :color="colors[1]"
                    ><v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div v-if="cupon.descripcion != undefined">
                  <b>Descripción:</b> 30% de descuento en productos de cuidado
                  facial. <br /><b>CUPÓN AGREGADO</b>
                </div>
                <div v-else style="margin-top:10px">
                  <b>CÓDIGO NO VÁLIDO</b>
                </div>
              </v-card-text>
            </v-card>
          </div>

          <div style="margin-top:20px">
            <v-card>
              <v-card-title
                class="text-body-1 font-weight-bold"
                :style="
                  'max-height:50px;color:white;background-color:' + colors[0]
                "
              >
                <div
                  style="margin-top:-10px;width:100%;display:flex;align-items:center"
                >
                  <span style="margin-top:7px">DATOS DEL PEDIDO </span>
                </div>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text style="height:100%">
                <v-form
                  ref="formPedido"
                  v-model="formPedidoValid"
                  lazy-validation
                >
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    autocomplete="name"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="pedido.nombre"
                    label="Nombre Completo"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="numeroRules"
                    required
                    hide-details="auto"
                    v-model="pedido.ci"
                    label="Cédula / RUC"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <span
                    v-if="
                      $refs.celular0 === undefined || !$refs.celular0.isValid
                    "
                    style="color:red;font-size:11px"
                    >Número de Teléfono no válido.</span
                  >
                  <VuePhoneNumberInput
                    ref="celular0"
                    style="margin-bottom:17px;border: 1.5px #9E9E9E solid;border-radius:5px"
                    :color="colors[0]"
                    v-model="pedido.celular"
                    defaultCountryCode="EC"
                    :translations="{
                      countrySelectorLabel: 'Código de País',
                      countrySelectorError: 'Elija el País',
                      phoneNumberLabel: 'Número de Teléfono',
                      example: 'Ejemplo:',
                    }"
                  />
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emailRules"
                    required
                    hide-details="auto"
                    v-model="pedido.correo"
                    label="Correo Electrónico"
                    type="email"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="pedido.dir"
                    label="Dirección"
                    placeholder="Calles, número, conjunto, casa/dpto"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="pedido.pais"
                    label="País"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="pedido.ciudad"
                    label="Ciudad"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-card>
          </div>

          <div style="margin-top:20px">
            <v-card>
              <v-card-title
                class="text-body-1 font-weight-bold"
                :style="
                  'max-height:50px;color:white;background-color:' + colors[0]
                "
              >
                <div
                  style="margin-top:-10px;width:100%;display:flex;align-items:center"
                >
                  <span style="margin-top:-15px"
                    >DATOS DE FACTURACIÓN
                    <span v-if="!showFact && !isMobile" style="opacity:0.5"
                      >(Consumidor Final)</span
                    >
                  </span>

                  <v-switch
                    dark
                    style="margin-left:auto;margin-top:5px;"
                    color="white"
                    v-model="showFact"
                    :label="showFact ? 'SÍ' : 'NO'"
                  ></v-switch>
                </div>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text v-if="showFact" style="height:100%">
                <v-form ref="formFact" v-model="formFactValid" lazy-validation>
                  <v-checkbox
                    style="margin-top:-5px"
                    label="Factura con los mismos datos"
                    :color="colors[0]"
                    v-model="copiaDatos"
                  ></v-checkbox>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    autocomplete="name"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="fact.nombre"
                    label="Nombre Completo"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="numeroRules"
                    required
                    hide-details="auto"
                    v-model="fact.ci"
                    label="Cédula / RUC"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <span
                    v-if="$refs.celular === undefined || !$refs.celular.isValid"
                    style="color:red;font-size:11px"
                    >Número de Teléfono no válido.</span
                  >
                  <VuePhoneNumberInput
                    ref="celular"
                    style="margin-bottom:17px;border: 1.5px #9E9E9E solid;border-radius:5px"
                    :color="colors[0]"
                    v-model="fact.celular"
                    defaultCountryCode="EC"
                    :translations="{
                      countrySelectorLabel: 'Código de País',
                      countrySelectorError: 'Elija el País',
                      phoneNumberLabel: 'Número de Teléfono',
                      example: 'Ejemplo:',
                    }"
                  />
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emailRules"
                    required
                    hide-details="auto"
                    v-model="fact.correo"
                    label="Correo Electrónico"
                    type="email"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="fact.dir"
                    label="Dirección"
                    placeholder="Calles, número, conjunto, casa/dpto"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="fact.pais"
                    label="País"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="fact.ciudad"
                    label="Ciudad"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-card>
          </div>

          <div style="margin-top:20px">
            <v-card>
              <v-card-title
                class="text-body-1 font-weight-bold"
                :style="
                  'max-height:50px;color:white;background-color:' + colors[0]
                "
              >
                <div
                  style="margin-top:-10px;width:100%;display:flex;align-items:center"
                >
                  <span style="margin-top:-15px">ENVIAR A OTRA DIRECCIÓN</span>

                  <v-switch
                    dark
                    style="margin-left:auto;margin-top:5px;"
                    color="white"
                    v-model="showOtra"
                    :label="showOtra ? 'SÍ' : 'NO'"
                  ></v-switch>
                </div>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text v-if="showOtra" style="height:100%">
                <v-form ref="formOtra" v-model="formOtraValid" lazy-validation>
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    autocomplete="name"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="otra.nombre"
                    label="Nombre Completo"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                  <span
                    v-if="
                      $refs.celular2 === undefined || !$refs.celular2.isValid
                    "
                    style="color:red;font-size:11px"
                    >Número de Teléfono no válido.</span
                  >
                  <VuePhoneNumberInput
                    ref="celular2"
                    style="margin-bottom:17px;border: 1.5px #9E9E9E solid;border-radius:5px"
                    :color="colors[0]"
                    v-model="otra.celular"
                    defaultCountryCode="EC"
                    :translations="{
                      countrySelectorLabel: 'Código de País',
                      countrySelectorError: 'Elija el País',
                      phoneNumberLabel: 'Número de Teléfono',
                      example: 'Ejemplo:',
                    }"
                  />
                  <v-text-field
                    dense
                    style="margin-bottom:10px"
                    :rules="emptyRules"
                    required
                    hide-details="auto"
                    v-model="otra.dir"
                    label="Dirección"
                    placeholder="Calles, número, conjunto, casa/dpto"
                    clearable
                    outlined
                    :color="colors[0]"
                  ></v-text-field>
                </v-form>
              </v-card-text>
            </v-card>
          </div>

          <div style="margin-top:20px">
            <v-card>
              <v-card-title
                class="text-body-1 font-weight-bold"
                :style="
                  'max-height:50px;color:white;background-color:' + colors[0]
                "
              >
                <div
                  style="margin-top:10px;width:100%;display:flex;align-items:center"
                >
                  <span style="margin-top:-15px">ENVIO &amp; PAGO</span>
                </div>
              </v-card-title>

              <v-divider></v-divider>
              <v-card-text style="height:100%">
                <v-radio-group v-model="envio" mandatory>
                  <template v-slot:label>
                    <div>
                      Seleccione su preferencia de <strong>envío.</strong>
                    </div>
                  </template>
                  <v-radio
                    :color="colors[0]"
                    v-for="(radio, i) in radioEnvio"
                    :key="i"
                    :value="radio.value"
                  >
                    <template v-slot:label>
                      <div>
                        {{ radio.value }}
                        <strong
                          v-if="envio == radio.value"
                          class="success--text"
                          >{{ radio.precio }}</strong
                        >
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
                <v-radio-group v-model="pago" mandatory>
                  <template v-slot:label>
                    <div>Seleccione su método de <strong>pago.</strong></div>
                  </template>
                  <v-radio
                    :color="colors[0]"
                    v-for="(radio, i) in radioPago"
                    :key="i"
                    :value="radio.value"
                  >
                    <template v-slot:label>
                      <div>
                        <strong>{{ radio.value }}</strong> <br />
                        <span style="font-size:13px" v-if="pago == radio.value"
                          >{{ radio.hint }}
                        </span>
                      </div>
                    </template>
                  </v-radio>
                </v-radio-group>
              </v-card-text>
            </v-card>
          </div>

          <v-textarea
            dense
            style="margin-top:30px"
            v-model="notas"
            label="Notas adicionales al pedido (OPCIONAL)"
            placeholder="Notas sobre tu pedido, por ejemplo: alguna observación especial, factura a otros datos, etc."
            clearable
            outlined
            :color="colors[0]"
          ></v-textarea>
          <div style="margin-top:-5px;color:#666666">
            Recuerda que <b>NO</b> debes realizar el pago hasta que nos
            contactemos contigo para confirmar la disponibilidad de todos los
            productos del pedido.
          </div>
          <div>
            <v-btn dark class="mt-10" :color="colors[0]" @click="armarURL">
              FINALIZAR COMPRA <v-icon class="ml-2">mdi-whatsapp</v-icon>
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { colors, cuponesDB } from "../main";
import { onSnapshot } from "firebase/firestore";
import VuePhoneNumberInput from "vue-phone-number-input";

export default {
  name: "Carrito",
  components: {
    VuePhoneNumberInput,
  },
  watch: {
    copiaDatos() {
      if (this.copiaDatos) {
        this.fact = { ...this.pedido };
        this.$refs.celular.results = { ...this.$refs.celular0.results };
        //console.log(this.$refs.celular.results);
      } else {
        this.fact = {
          pais: "Ecuador",
          ciudad: "Quito",
        };
        this.$refs.celular.results.isValid = false;
      }
    },
  },
  data() {
    return {
      isMobile: this.Vue_window.screen.width <= 960,
      colors: colors,
      productos: [],
      showCupon: false,
      showFact: false,
      showOtra: false,
      codigo_cupon: "",
      carrito_num: 0,
      cupon: {},
      cupones: {},
      notas: "",
      fact: {
        pais: "Ecuador",
        ciudad: "Quito",
      },
      pedido: {
        pais: "Ecuador",
        ciudad: "Quito",
      },
      copiaDatos: false,
      otra: {},
      formFactValid: true,
      formOtraValid: true,
      formPedidoValid: true,
      envio: "Quito",
      pago: "Transferencia bancaria",
      radioEnvio: [
        { value: "Quito", precio: "$ 3.50" },
        { value: "Cumbayá y Tumbaco", precio: "$ 3.00" },
        { value: "Resto del país", precio: "$ 5.50" },
        { value: "Retiro en tienda", precio: "$ 0.00" },
      ],
      radioPago: [
        {
          value: "Transferencia bancaria",
          hint: "Te enviaremos nuestros datos bancarios por WhatsApp.",
        },
        {
          value: "Tarjeta vía Payphone",
          hint: "Te enviaremos un link de pago por WhatsApp.",
        },
      ],
      numeroRules: [
        (value) => !!value || "Este campo es Obligatorio.",
        (value) =>
          (value && value.length >= 10) ||
          "Este campo debe tener mínimo 10 caracteres.",
        (value) =>
          (value && value.length <= 20) ||
          "Este campo no puede tener muchos caracteres.",
        (value) => {
          const pattern = /^[0-9]+$/;
          return pattern.test(value) || "Cédula o RUC no válidos.";
        },
      ],
      emailRules: [
        (value) => !!value || "Este campo es Obligatorio.",
        (value) =>
          (value && value.length <= 50) ||
          "Este campo no puede tener muchos caracteres.",
        (value) => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Correo electrónico no válido.";
        },
      ],
      emptyRules: [
        (value) => !!value || "Este campo es Obligatorio.",
        (value) =>
          (value && value.length <= 40) ||
          "Este campo no puede tener muchos caracteres.",
      ],
      headersP: [
        {
          text: "Nombre",
          align: "start",
          value: "nombre",
          width: "300",
        },
        {
          text: "Presentación",
          value: "presentacion",
          sortable: false,
          align: "center",
          width: "150",
        },
        {
          text: "Cantidad",
          value: "cantidad",
          sortable: false,
          align: "center",
        },
        {
          text: "Precio Unitario",
          value: "precio",
          sortable: false,
          align: "center",
        },
        {
          text: "Total",
          value: "total",
          sortable: false,
          align: "center",
          width: "100",
        },
        {
          text: "Eliminar",
          value: "actions",
          sortable: false,
          align: "center",
        },
      ],
      emojiNums: ["0️⃣", "1️⃣", "2️⃣", "3️⃣", "4️⃣", "5️⃣", "6️⃣", "7️⃣", "8️⃣", "9️⃣"],
    };
  },
  mounted() {
    this.cargaCarrito();
  },
  methods: {
    test() {
      console.log(this.$refs.celular.results);
    },
    cargaCarrito() {
      let productos = JSON.parse(window.$cookies.get("productos"));
      this.productos = productos == null ? [] : productos;
      this.carrito_num = this.productos.length;
      Date.prototype.addDays = function(days) {
        this.setDate(this.getDate() + parseInt(days));
        return this;
      };

      onSnapshot(cuponesDB, (snapshot) => {
        this.cupones = {};
        snapshot.forEach((doc) => {
          this.cupones[doc.data()["codigo"]] = {
            codigo: doc.data()["codigo"],
            descripcion: doc.data()["descripcion"],
            descuento: doc.data()["descuento"],
            ffin: doc.data()["fecha_fin"],
          };
        });
      });
    },
    deleteProducto(item) {
      this.editedIndex = this.productos.indexOf(item);
      this.productos.splice(this.editedIndex, 1);
      window.$cookies.set("productos", JSON.stringify(this.productos));
      this.$parent.$parent.$parent.updateCarrito();
      this.cargaCarrito();
    },
    calculaTotal() {
      let total = 0.0;
      this.productos.forEach((p) => {
        total += parseFloat(p.precio) * parseFloat(p.cantidad);
      });
      return total;
    },
    esValido() {
      return (
        this.cupones[this.codigo_cupon] != undefined &&
        new Date(this.cupones[this.codigo_cupon].ffin).addDays(2) >= new Date()
      );
    },
    validaCodigo() {
      if (this.esValido()) {
        this.cupon = this.cupones[this.codigo_cupon];
      } else {
        this.cupon = {};
      }
    },
    isDataValid() {
      let datosValidos = true;
      if (this.showFact) {
        //Si tiene datos de facturacion
        const factura_valida =
          this.$refs.formFact.validate() &&
          (this.$refs.celular === undefined ||
            this.$refs.celular.isValid === true);
        datosValidos = factura_valida;
      }
      if (this.showOtra) {
        const otra_valida =
          this.$refs.formOtra.validate() &&
          (this.$refs.celular2 === undefined ||
            this.$refs.celular2.isValid === true);
        datosValidos = datosValidos && otra_valida;
      }
      const pedido_valido =
        this.$refs.formPedido.validate() &&
        (this.$refs.celular0 === undefined ||
          this.$refs.celular0.isValid === true);
      datosValidos = datosValidos && pedido_valido;
      return datosValidos;
    },
    emojiNumero(num) {
      let emojiNum = "";
      let textNum = num + "";
      for (let i = 0; i < textNum.length; i++) {
        const digit = textNum[i];
        if (digit !== "." && digit !== ",") {
          emojiNum += this.emojiNums[digit];
        } else {
          emojiNum += ".";
        }
      }
      return emojiNum;
    },
    armarURL() {
      if (this.isDataValid()) {
        let mensajeSimple = "";
        let mensaje =
          "¡Hola! *Verde Granel -EcoMarket-* emojivisto\n\nEste es mi pedido de la tienda:\n\n--------------------------------------------------\n";

        if (this.productos.length != 0) {
          mensajeSimple += "";
          this.productos.forEach((p) => {
            mensajeSimple +=
              "" +
              this.emojiNumero(p.cantidad) +
              " " +
              p.nombre +
              (p.presentacion != "N/A" ? " (" + p.presentacion + ")" : "") +
              "\n*$ " +
              parseFloat(parseFloat(p.precio) * p.cantidad).toFixed(2) +
              "*\n--------------------------------------------------\n";
          });
        }

        mensajeSimple +=
          "\n*emojidollar TOTAL:*\n💲" +
          this.emojiNumero(parseFloat(this.calculaTotal()).toFixed(2));

        if (this.cupon.descripcion !== undefined) {
          mensajeSimple += "\n\n*CUPÓN:*";
          mensajeSimple += "\n```" + this.cupon.codigo + "```";
          mensajeSimple += "\n*Descripción:* " + this.cupon.descripcion;
        }

        mensajeSimple += "\n\n*DATOS DEL PEDIDO:*";
        mensajeSimple += "\n*Nombre:* " + this.pedido.nombre;
        mensajeSimple += "\n*Cédula/RUC:* " + this.pedido.ci;
        mensajeSimple +=
          "\n*Celular:* " + this.$refs.celular0.results.formatInternational;
        mensajeSimple += "\n*Correo:* " + this.pedido.correo;
        mensajeSimple += "\n*Dirección:* " + this.pedido.dir;
        mensajeSimple +=
          "\n*Ubicación:* " + this.pedido.ciudad + ", " + this.pedido.pais;

        if (this.showFact) {
          mensajeSimple += "\n\n*FACTURA:*";
          mensajeSimple += "\n*Nombre:* " + this.fact.nombre;
          mensajeSimple += "\n*Cédula/RUC:* " + this.fact.ci;
          mensajeSimple +=
            "\n*Celular:* " + this.$refs.celular.results.formatInternational;
          mensajeSimple += "\n*Correo:* " + this.fact.correo;
          mensajeSimple += "\n*Dirección:* " + this.fact.dir;
          mensajeSimple +=
            "\n*Ubicación:* " + this.fact.ciudad + ", " + this.fact.pais;
        } else {
          mensajeSimple += "\n\n*C O N S U M I D O R   F I N A L*";
        }

        if (this.showOtra) {
          mensajeSimple += "\n\n*ENVIAR A OTRA DIRECCIÓN:*";
          mensajeSimple += "\n*Nombre:* " + this.otra.nombre;

          mensajeSimple +=
            "\n*Celular:* " + this.$refs.celular2.results.formatInternational;

          mensajeSimple += "\n*Dirección:* " + this.otra.dir;
        }

        mensajeSimple += "\n\n*PREFERENCIA DE ENVÍO:*";
        mensajeSimple += "\n```" + this.envio + "```";

        mensajeSimple += "\n\n*MÉTODO DE PAGO:*";
        mensajeSimple += "\n```" + this.pago + "```";

        if (this.notas.length > 0) {
          mensajeSimple += "\n\n*Notas adicionales:*";
          mensajeSimple += "\n" + this.notas;
        }

        mensajeSimple +=
          "\n\nemojicamion Costos de Envío serán aplicados posteriormente.\n\n" +
          "_Verde Granel -EcoMarket- ® *" +
          new Date().getFullYear() +
          "*_\nhttps://" +
          window.location.host;

        mensaje += mensajeSimple;

        window
          .open(
            "https://api.whatsapp.com/send/?phone=593988981129&text=" +
              encodeURIComponent(mensaje)
                .replace("emojivisto", "✅")
                .replace("emojidollar", "💰")
                .replace("emojicamion", "🚛"),
            "_blank"
          )
          .focus();
      } else {
        alert("Por favor, llene los datos correctamente.");
      }
    },
  },
};
</script>
