<template>
  <div class="centrar" ref="Tienda">
    <div class="centrar" style="padding:0px;width:100%;margin-bottom:20px">
      <v-img src="../assets/banner.jpg" height="300" style="width:100vw;">
        <div
          v-if="isMobile"
          class="centrar"
          style="width:100%;height:100%;align-items:flex-start;padding-left:10px"
        >
          <img
            src="../assets/logo_white.png"
            style="width:35%;max-width:200px"
          />
        </div>
      </v-img>
    </div>
    <span style="margin-bottom:10px">Filtrar Por Categorías:</span>
    <div style="max-width:100%">
      <v-slide-group show-arrows v-model="selectedItems" multiple center-active>
        <v-slide-item
          v-for="(cat, i) in categorias"
          :key="i"
          v-slot="{ active, toggle }"
        >
          <v-btn
            class="mx-1"
            :input-value="active"
            active-class="light-green darken-4 white--text"
            depressed
            rounded
            @click="
              () => {
                toggle();
                llenaCats();
              }
            "
            :value="cat"
            :small="!isMobile"
            :x-small="isMobile"
            height="30px"
          >
            {{ cat.nombre }}
          </v-btn>
        </v-slide-item>
      </v-slide-group>
    </div>
    <v-container
      style="width:100%;background-color:rgb(245,245,245);padding:20px;margin-top:20px"
      fluid
    >
      <v-data-iterator
        :items="
          items.filter((e) => {
            let tiene = false;
            e.categoria.forEach((cat) => {
              if (!tiene) {
                tiene = selectedCats.includes(cat);
              }
            });
            if (selectedCats.length === 0) {
              tiene = true;
            }
            return tiene;
          })
        "
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :search="$parent.$parent.$parent.buscar"
        :sort-by="sortBy.toLowerCase()"
        :sort-desc="sortDesc"
        hide-default-footer
      >
        <template v-slot:header v-if="false">
          <v-toolbar dark color="grey darken-3" class="mb-4">
            <template>
              <span v-if="!isMobile" style="margin-right:10px"
                >Ordenar Por:</span
              >
              <v-select
                v-model="sortBy"
                flat
                :color="colors[0]"
                solo-inverted
                hide-details
                :items="keys"
                prepend-inner-icon="mdi-sort"
                label="Ordenar Por"
                style="max-width:175px"
                :item-color="colors[0]"
              ></v-select>
              <v-spacer></v-spacer>
              <v-btn-toggle v-model="sortDesc" mandatory>
                <v-btn depressed :color="colors[0]" :value="false">
                  <v-icon v-if="isMobile">mdi-arrow-up</v-icon>
                  <span v-else>Ascendente</span>
                </v-btn>
                <v-btn depressed :color="colors[0]" :value="true">
                  <v-icon v-if="isMobile">mdi-arrow-down</v-icon>
                  <span v-else>Descendente</span>
                </v-btn>
              </v-btn-toggle>
            </template>
          </v-toolbar>
        </template>

        <template v-slot:default="props">
          <v-row>
            <v-col
              v-for="item in props.items.filter((e) => {
                let tiene = false;
                e.categoria.forEach((cat) => {
                  if (!tiene) {
                    tiene = selectedCats.includes(cat);
                  }
                });
                if (selectedCats.length === 0) {
                  tiene = true;
                }
                return tiene;
              })"
              :key="item.nombre"
              cols="12"
              sm="6"
              md="4"
              lg="3"
            >
              <v-hover v-slot="{ hover }">
                <v-card
                  :elevation="hover ? 7 : 1.5"
                  :class="{ 'on-hover': hover, 'opaco-producto': !hover }"
                  @click="
                    $parent.$parent.$parent.cambiaTab({
                      nombre: 'Tienda',
                      ruta: '/Tienda/all/' + item.key,
                    })
                  "
                >
                  <v-card-title
                    class="subheading font-weight-bold"
                    style="height:360px"
                  >
                    <v-carousel
                      cycle
                      height="300"
                      hide-delimiter-background
                      show-arrows-on-hover
                    >
                      <v-carousel-item
                        v-for="(foto, i) in item.fotos.split('*')"
                        :key="i"
                      >
                        <v-sheet width="100%" color="transparent">
                          <v-row
                            class="fill-width"
                            align="center"
                            justify="center"
                            style="height:300px"
                          >
                            <img
                              style="max-height:100%;"
                              :src="foto"
                              :alt="'foto_' + i"
                            />
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                    <span style="font-size:17.5px;word-break: keep-all;">{{
                      item.nombre
                    }}</span>
                  </v-card-title>
                  <div
                    style="width:100%;display:flex;padding-right:20px;padding-bottom:20px"
                  >
                    <div
                      style="width:100%;font-size:28px;word-break: keep-all;display:flex;flex-direction:column"
                      class="text-right"
                    >
                      <span style="font-size:12px">PRECIO</span>
                      <div style="font-weight:bold">
                        <span
                          :style="
                            'font-size:18px;text-decoration:line-through;font-weight:normal;flex-direction:row;text-decoration-color:' +
                              colors[0]
                          "
                          v-if="item.oferta == '1'"
                          >${{
                            item.precio.split("-").length !== 1
                              ? parseFloat(item.precio.split("-")[0]).toFixed(
                                  2
                                ) +
                                "-$" +
                                parseFloat(item.precio.split("-")[1]).toFixed(2)
                              : parseFloat(item.precio).toFixed(2)
                          }}</span
                        ><span
                          style="
                            font-size:23px;"
                        >
                          ${{
                            item.oferta == "1"
                              ? item.descuento.split("-").length !== 1
                                ? parseFloat(
                                    item.descuento.split("-")[0]
                                  ).toFixed(2) +
                                  "-$" +
                                  parseFloat(
                                    item.descuento.split("-")[1]
                                  ).toFixed(2)
                                : parseFloat(item.descuento).toFixed(2)
                              : item.precio.split("-").length !== 1
                              ? parseFloat(item.precio.split("-")[0]).toFixed(
                                  2
                                ) +
                                "-$" +
                                parseFloat(item.precio.split("-")[1]).toFixed(2)
                              : parseFloat(item.precio).toFixed(2)
                          }}</span
                        >
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-hover>
            </v-col>
          </v-row>
        </template>

        <template v-slot:footer>
          <v-row class="mt-2" align="center" justify="center">
            <span class="grey--text">Productos Por Página</span>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  dark
                  text
                  :color="colors[0]"
                  class="ml-2"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ itemsPerPage }}
                  <v-icon>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(number, index) in itemsPerPageArray"
                  :key="index"
                  @click="updateItemsPerPage(number)"
                >
                  <v-list-item-title>{{ number }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-spacer></v-spacer>

            <span
              class="mr-4
            grey--text"
            >
              Página {{ page }} de {{ numberOfPages }}
            </span>
            <v-btn
              fab
              dark
              :color="colors[0]"
              class="mr-1"
              small
              @click="formerPage"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-btn
              fab
              dark
              :color="colors[0]"
              class="ml-1"
              small
              @click="nextPage"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-iterator>
    </v-container>
  </div>
</template>

<script>
import { categoriasDB, productosDB, colors } from "../main";
import { onSnapshot } from "firebase/firestore";
export default {
  name: "Tienda",
  data() {
    return {
      isMobile: this.Vue_window.screen.width <= 960,
      categorias: [],
      colors: colors,
      selectedCats: [],
      selectedItems: [],
      productos: productosDB,
      producto: {},
      itemsPerPageArray: [8, 12, 20, 100, 200, 300],
      filter: {},
      sortDesc: false,
      page: 1,
      itemsPerPage: 20,
      sortBy: "Nombre",
      keys: ["Nombre", "Descripcion", "Precio", "Descuento"],
      items: [],
      hoverCard: 1.5,
    };
  },
  computed: {
    filtroAll() {
      return this.selectedCats.length === this.categorias.length;
    },
    filtroSome() {
      return this.selectedCats.length > 0 && !this.filtroAll;
    },
    icon() {
      if (this.filtroAll) return "mdi-close-box";
      if (this.filtroSome) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== "nombre");
    },
  },
  created() {
    for (let i = 0; i < 150; i++) {
      this.categorias.push("_________________________");
    }
  },
  mounted() {
    onSnapshot(categoriasDB, (snapshot) => {
      this.categorias = [];
      snapshot.forEach((doc) => {
        this.categorias.push({
          key: doc.id,
          nombre: doc.data()["nombre"],
        });
      });
    });
    onSnapshot(this.productos, (snapshot) => {
      this.items = [];
      snapshot.docs.forEach((doc) => {
        if (doc.data()["estado"] == true) {
          this.items.push({
            key: doc.id,
            nombre: doc.data()["nombre"],
            descripcion: doc.data()["descripcion"],
            categoria: doc.data()["categoria"],
            fotos: doc.data()["fotos"],
            precio: doc.data()["precio"].replaceAll(",", "."),
            descuento: doc.data()["descuento"].replaceAll(",", "."),
            oferta:
              doc.data()["descuento"] != null &&
              doc.data()["descuento"] != 0 &&
              doc.data()["descuento"] != "0"
                ? "1"
                : "0",
          });
        }
      });
    });
    if (this.$props.filtro != undefined) {
      this.selectedCats = [this.$props.filtro];
    }
  },
  props: {
    filtro: {
      type: String,
      required: false,
    },
  },

  methods: {
    llenaCats() {
      this.selectedCats = [];
      this.selectedItems.forEach((id) => {
        this.selectedCats.push(this.categorias[id].nombre);
      });
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
  },
};
</script>
