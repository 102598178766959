<template>
  <div
    style="text-align: center; width: 100%"
    ref="Nosotros"
    v-if="pagina != undefined && pagina.nosotros != undefined"
  >
    <div
      class="text-h5 text-md-h5"
      :style="
        'height: ' +
          (isMobile ? '200px' : '100px') +
          ';' +
          'width: 100%;' +
          'background-color:' +
          colors[1] +
          ';' +
          'color: white;' +
          'display: flex;flex-direction:column;' +
          'align-items: center;' +
          'justify-content: center;' +
          'padding: 25px;'
      "
    >
      <span style="font-weight: 600"
        >VERDE GRANEL <br v-if="isMobile" />
        -ECOMARKET-</span
      >
      <span class="font-italic mt-2" style="font-family: 'Satisfy';">{{
        pagina.nosotros.banner
      }}</span>
    </div>
    <div style="width: 100%; margin-top: 50px">
      <div class="centrar">
        <div
          class="centrar"
          :style="
            `
            flex-direction: row;
            padding: 10px;
            border-radius: 10px;
            color:` + colors[0]
          "
        >
          <span
            class="text-h5 text-md-h4"
            style="margin-left: 5px; font-weight: 600;"
            >- {{ pagina.nosotros.titulo }} -</span
          >
        </div>
        <v-container style="padding: 30px">
          <v-row>
            <v-col cols="12" md="6">
              <p
                class="text-md-body-1"
                style="margin-top: 15px; width: 100%; text-align: justify"
              >
                {{ pagina.nosotros.parrafo1 }}
              </p>
              <p
                class="text-md-body-1"
                :style="
                  'margin-top: 15px; width: 100%; text-align: justify;' +
                    (isMobile ? 'opacity:0' : '')
                "
                v-animate-onscroll="isMobile ? 'animated fadeInUp' : ''"
              >
                {{ pagina.nosotros.parrafo2_1 }}
                <span class="font-weight-black body-1">{{
                  pagina.nosotros.parrafo2_2
                }}</span>
                {{ pagina.nosotros.parrafo2_3 }}
              </p>
              <p
                class="text-md-body-1"
                style="margin-top: 15px; width: 100%; text-align: justify;opacity:0"
                v-animate-onscroll="'animated fadeInUp'"
              >
                {{ pagina.nosotros.parrafo3 }}
              </p>
            </v-col>
            <v-col cols="12" md="6">
              <v-img
                v-animate-onscroll="isMobile ? 'animated zoomIn' : ''"
                :src="require('@/assets/fondo1.jpg')"
              ></v-img>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <span class="font-weight-medium text-h6 text-md-h4" style="color: #438019"
        >{{ pagina.nosotros.titulo2 }}
        <br />
        {{ pagina.nosotros.titulo3 }}
      </span>
      <v-container style="margin-top: 10px">
        <v-row>
          <v-col
            cols="12"
            md="6"
            lg="3"
            v-for="(tipo, i) in pagina.nosotros.tipos"
            :key="i"
          >
            <div
              class="centrar"
              :style="'opacity:0;color: ' + colors[1]"
              v-animate-onscroll="'animated fadeInLeft'"
            >
              <div class="centrar" style="width:100%;height:90px">
                <div
                  :style="
                    `
                  border: ${colors[0]} 3px solid;
                  padding: 10px;
                  border-radius: 10px;
                  max-width: 65%;
                  height: 90px;
                  display:flex;
                  align-items:center`
                  "
                >
                  <span class="font-weight-black text-h6 text-md-h5">
                    {{ tipo.titulo }}
                  </span>
                </div>
              </div>

              <span style="margin-top: 10px">
                {{ tipo.descrip }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <div style="width: 100%; margin-top: 30px;" class="centrar">
      <span class="font-weight-bold text-h5 text-md-h4 mb-3"
        >SOMOS PARTE DE:
      </span>
      <v-slide-group show-arrows center-active>
        <v-slide-item
          v-for="(logo, i) in pagina.nosotros.logos.split('\n')"
          :key="i"
        >
          <img :src="logo" height="80" :alt="'auspiciante_' + (i + 1)" />
        </v-slide-item>
      </v-slide-group>
    </div>
  </div>
</template>

<script>
import { paginaInfo, colors } from "../main";
import { onSnapshot } from "firebase/firestore";
export default {
  name: "Nosotros",
  data() {
    return {
      colors: colors,
      pagina: { seccion3: { pasos: [] } },
      isMobile: this.Vue_window.screen.width <= 960,
    };
  },
  mounted() {
    const unsub = onSnapshot(paginaInfo, (doc) => {
      this.pagina = doc.data();
    });
  },
  methods: {
    cargaData() {},
  },
};
</script>
