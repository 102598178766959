<template>
  <div class="centrar" style="padding:20px" ref="Tienda">
    <div style="width:95%;padding:5px;padding-top:15px;">
      <div>
        <v-chip
          class="text-body-2 mb-2"
          dark
          :color="colors[0]"
          @click="
            $parent.$parent.$parent.cambiaTab({
              nombre: 'Tienda',
              ruta: '/Tienda/all',
            })
          "
          >TIENDA</v-chip
        >
        <span class="text-h5"> / </span>
        <v-chip
          class="text-body-2 mb-2"
          @click="
            $parent.$parent.$parent.cambiaTab({
              nombre: 'Tienda',
              ruta: '/Tienda/all?filtro=' + producto.categoria[0],
            })
          "
          >{{ producto.categoria[0] }}</v-chip
        >
      </div>

      <v-container style="margin-top:15px">
        <v-row class="text-center">
          <v-col
            cols="12"
            style="padding:0px;display:flex;flex-direction:column;align-items:center;width:100vw;"
          >
            <!-- Se muestra solo cuando existe el producto -->

            <div v-if="producto.nombre != undefined">
              <div style="text-align:left;width:100vw;max-width:80vw">
                <v-row>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <v-carousel
                      cycle
                      hide-delimiter-background
                      :show-arrows-on-hover="true"
                      v-if="
                        producto.fotos != undefined &&
                          producto.fotos.split('*').length > 0
                      "
                    >
                      <v-carousel-item
                        v-for="(foto, i) in producto.fotos.split('*')"
                        :key="i"
                      >
                        <v-sheet width="100%" height="100%" color="#F3F3F3">
                          <v-row
                            class="fill-width"
                            align="center"
                            justify="center"
                            style="height:100%"
                          >
                            <img
                              style="max-width:100%;max-height: 100%;"
                              :src="foto"
                              :alt="'foto_' + i"
                            />
                          </v-row>
                        </v-sheet>
                      </v-carousel-item>
                    </v-carousel>
                    <span style="font-family:Calibri;font-weight:bolder;">
                      CATEGORÍA/S
                      <br />
                      <v-btn
                        v-for="(cat, i) in producto.categoria"
                        :key="i"
                        class="mr-1 mb-1 light-green darken-3 white--text"
                        depressed
                        rounded
                        :value="cat"
                        :small="!isMobile"
                        :x-small="isMobile"
                        height="30px"
                        @click="
                          $parent.$parent.$parent.cambiaTab({
                            nombre: 'Tienda',
                            ruta: '/Tienda/all?filtro=' + cat,
                          })
                        "
                      >
                        {{ cat }}
                      </v-btn>
                    </span>
                  </v-col>
                  <v-col cols="12" md="6" sm="12" xs="12">
                    <div style="height:100%;display:flex;flex-direction:column">
                      <span>
                        <span
                          class="text-h5"
                          :style="
                            'font-family:Calibri;font-weight:bold;color:' +
                              'black'
                          "
                        >
                          {{ producto.nombre }}
                        </span>

                        <br />
                        <span
                          v-if="producto.estado == false"
                          style="opacity:0.5"
                          >Producto NO disponible por el momento.</span
                        >
                        <div
                          v-if="presentaciones.length !== 0"
                          style="margin-top:15px"
                        >
                          <span style="font-family:Calibri;font-weight:bolder;">
                            PRESENTACIÓN
                          </span>
                          <v-select
                            :item-color="colors[0]"
                            v-model="presentacion"
                            outlined
                            :items="presentaciones"
                            :color="colors[0]"
                            label=""
                          >
                            <template v-slot:selection="{ item }">
                              {{ !hasPrices ? item : item.split(";")[0] }}
                              <v-chip
                                style="margin-left:10px"
                                :color="colors[0]"
                                dark
                                v-if="hasPrices"
                                >$
                                {{
                                  parseFloat(
                                    item.split(";")[1].replaceAll(",", ".")
                                  ).toFixed(2)
                                }}
                              </v-chip>
                            </template>
                            <template
                              v-slot:item="{
                                item,
                              }"
                            >
                              <v-divider class="mb-2"></v-divider>
                              <v-list-item>
                                {{ !hasPrices ? item : item.split(";")[0] }}
                                <v-chip
                                  style="margin-left:10px"
                                  :color="colors[0]"
                                  dark
                                  v-if="hasPrices"
                                  >$
                                  {{
                                    parseFloat(
                                      item.split(";")[1].replaceAll(",", ".")
                                    ).toFixed(2)
                                  }}
                                </v-chip>
                              </v-list-item>
                            </template>
                          </v-select>
                        </div>
                      </span>
                      <div class="text-h5" style="word-break: keep-all;">
                        <span
                          :style="
                            'font-family:Calibri;font-weight:bolder;color:' +
                              colors[1]
                          "
                        >
                          $
                          {{
                            String(
                              producto.oferta == "1"
                                ? hasPrices
                                  ? parseFloat(
                                      producto.descuento
                                        .split("-")[0]
                                        .replaceAll(",", ".")
                                    ).toFixed(2) +
                                    " - $ " +
                                    parseFloat(
                                      producto.descuento
                                        .split("-")[1]
                                        .replaceAll(",", ".")
                                    ).toFixed(2)
                                  : parseFloat(
                                      producto.descuento.replaceAll(",", ".")
                                    ).toFixed(2)
                                : hasPrices
                                ? parseFloat(
                                    producto.precio
                                      .split("-")[0]
                                      .replaceAll(",", ".")
                                  ).toFixed(2) +
                                  " - $ " +
                                  parseFloat(
                                    producto.precio
                                      .split("-")[1]
                                      .replaceAll(",", ".")
                                  ).toFixed(2)
                                : parseFloat(
                                    producto.precio.replaceAll(",", ".")
                                  ).toFixed(2)
                            )
                          }}
                          <span
                            class="text-h6"
                            :style="
                              'text-decoration:line-through;color:#666666;text-decoration-color:' +
                                colors[0]
                            "
                            v-if="producto.oferta == '1'"
                            >$
                            {{
                              hasPrices
                                ? parseFloat(
                                    producto.precio
                                      .split("-")[0]
                                      .replaceAll(",", ".")
                                  ).toFixed(2) +
                                  " - $ " +
                                  parseFloat(
                                    producto.precio
                                      .split("-")[1]
                                      .replaceAll(",", ".")
                                  ).toFixed(2)
                                : parseFloat(
                                    producto.precio.replaceAll(",", ".")
                                  ).toFixed(2)
                            }}
                          </span>
                        </span>
                      </div>

                      <br />
                      <div>
                        <span style="font-family:Calibri;font-weight:bolder;">
                          CANTIDAD
                        </span>
                        <br />
                        <div style="display:flex">
                          <div style="margin-top:10px;display:flex">
                            <v-btn
                              outlined
                              tile
                              :color="colors[3]"
                              @click="changeQ(-1, 1)"
                              x-small
                              style="height:40px;padding:0px;"
                              class="rounded-l-lg"
                            >
                              <v-icon>mdi-minus</v-icon>
                            </v-btn>
                            <v-btn
                              outlined
                              tile
                              plain
                              :ripple="false"
                              x-small
                              :color="colors[3]"
                              style="height:40px;padding:0px"
                            >
                              <span class="text-h6">{{
                                carrito_new.cantidad
                              }}</span>
                            </v-btn>
                            <v-btn
                              outlined
                              tile
                              :color="colors[3]"
                              @click="changeQ(1, 1)"
                              x-small
                              style="height:40px;padding:0px"
                              class="rounded-r-lg"
                            >
                              <v-icon size="25">mdi-plus</v-icon>
                            </v-btn>
                          </div>
                          <v-btn
                            style="margin-top:10px;height:40px;margin-left:5px"
                            dark
                            small
                            :disabled="producto.estado == false"
                            :color="colors[0]"
                            @click="addProducto"
                            >Añadir al Carrito</v-btn
                          >
                        </div>
                      </div>

                      <br />
                      <p
                        v-html="producto.descripcion"
                        style="margin-top:5px;font-size:17.5px;white-space:pre-wrap"
                      ></p>
                      <div>
                        <!-- <div
                          style="font-family:Calibri;font-size:15px;height:20px;display:flex;align-items:center;margin-bottom:10px;margin-top:25px"
                        >
                          <v-icon style="font-size:35px;margin-right:8px"
                            >mdi-truck-delivery-outline</v-icon
                          >
                          Costos de Envío serán calculados previo al pago.
                        </div> -->
                        <br />
                        <div
                          style="font-family:Calibri;font-size:15px;height:20px;display:flex;align-items:center;margin-top:5px"
                        >
                          <img
                            v-if="!producto.importado"
                            src="../assets/ec.jpeg"
                            style="width:35px;margin-right:8px"
                          />
                          <img
                            v-else
                            src="../assets/world.jpeg"
                            style="height:32px;margin-right:5px;margin-left:-3px"
                          />
                          {{ producto.origen }}
                        </div>
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </div>

              <div
                style="text-align:left;width:100vw;max-width:80vw;margin-top:40px"
              >
                <v-divider></v-divider>
                <div class="centrar" style="margin-top:20px;">
                  <span
                    class="text-h6 text-md-h5 mb-4"
                    style="font-family: Calibri;font-weight:bolder;"
                  >
                    PRODUCTOS RELACIONADOS
                  </span>
                  <v-row>
                    <v-col
                      v-for="item in items"
                      :key="item.nombre"
                      cols="12"
                      sm="6"
                      :md="items.length === 3 ? 4 : items.length === 2 ? 6 : 12"
                      :lg="items.length === 3 ? 4 : items.length === 2 ? 6 : 12"
                    >
                      <v-hover v-slot="{ hover }">
                        <v-card
                          :elevation="hover ? 7 : 1.5"
                          :class="{
                            'on-hover': hover,
                            'opaco-producto': !hover,
                          }"
                          @click="cambiaProducto('/Tienda/all/' + item.key)"
                        >
                          <v-card-title
                            class="subheading font-weight-bold"
                            style="height:360px"
                          >
                            <v-carousel
                              cycle
                              height="300"
                              hide-delimiter-background
                              show-arrows-on-hover
                            >
                              <v-carousel-item
                                v-for="(foto, i) in item.fotos.split('*')"
                                :key="i"
                              >
                                <v-sheet width="100%" color="transparent">
                                  <v-row
                                    class="fill-width"
                                    align="center"
                                    justify="center"
                                    style="height:300px"
                                  >
                                    <img
                                      style="max-height:100%;"
                                      :src="foto"
                                      :alt="'foto_' + i"
                                    />
                                  </v-row>
                                </v-sheet>
                              </v-carousel-item>
                            </v-carousel>
                            <span
                              style="font-size:17.5px;word-break: keep-all;"
                              >{{ item.nombre }}</span
                            >
                          </v-card-title>
                          <div
                            style="width:100%;display:flex;padding-right:20px;padding-bottom:20px"
                          >
                            <div
                              style="width:100%;font-size:28px;word-break: keep-all;display:flex;flex-direction:column"
                              class="text-right"
                            >
                              <span style="font-size:12px">PRECIO</span>
                              <div style="font-weight:bold">
                                <span
                                  :style="
                                    'font-size:18px;text-decoration:line-through;font-weight:normal;flex-direction:row;text-decoration-color:' +
                                      colors[0]
                                  "
                                  v-if="item.oferta == '1'"
                                  >${{
                                    item.precio.split("-").length !== 1
                                      ? parseFloat(
                                          item.precio.split("-")[0]
                                        ).toFixed(2) +
                                        "-$" +
                                        parseFloat(
                                          item.precio.split("-")[1]
                                        ).toFixed(2)
                                      : parseFloat(item.precio).toFixed(2)
                                  }}</span
                                ><span
                                  style="
                            font-size:23px;"
                                >
                                  ${{
                                    item.oferta == "1"
                                      ? item.descuento.split("-").length !== 1
                                        ? parseFloat(
                                            item.descuento.split("-")[0]
                                          ).toFixed(2) +
                                          "-$" +
                                          parseFloat(
                                            item.descuento.split("-")[1]
                                          ).toFixed(2)
                                        : parseFloat(item.descuento).toFixed(2)
                                      : item.precio.split("-").length !== 1
                                      ? parseFloat(
                                          item.precio.split("-")[0]
                                        ).toFixed(2) +
                                        "-$" +
                                        parseFloat(
                                          item.precio.split("-")[1]
                                        ).toFixed(2)
                                      : parseFloat(item.precio).toFixed(2)
                                  }}</span
                                >
                              </div>
                            </div>
                          </div>
                        </v-card>
                      </v-hover>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>

            <div v-else>
              <div
                style="width:100vw;background-color:#E6E6E6;padding:15px;margin-bottom:40px"
              >
                <span
                  style="letter-spacing: 5px;font-family: Calibri;font-size:1.5rem;font-weight:bolder;"
                  >Producto No Existente</span
                >
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { productosDB, colors } from "../main";
import { onSnapshot } from "firebase/firestore";

export default {
  name: "Producto",
  data() {
    return {
      isMobile: this.Vue_window.screen.width <= 960,
      producto: { categoria: [""] },
      colors: colors,
      carrito_new: {
        cantidad: 1,
      },
      presentacion: "",
      presentaciones: [],
      hasPrices: false,
      items: [],
    };
  },
  mounted() {
    onSnapshot(productosDB, (snapshot) => {
      this.items = [];
      snapshot.docs.forEach((doc) => {
        if (doc.id == this.$route.params.key) {
          this.producto = {
            nombre: doc.data()["nombre"],
            descripcion: doc.data()["descripcion"],
            categoria: doc.data()["categoria"],
            fotos: doc.data()["fotos"],
            precio: doc.data()["precio"],
            descuento: doc.data()["descuento"],
            importado: doc.data()["importado"],
            origen: doc.data()["origen"],
            estado: doc.data()["estado"],
            oferta:
              doc.data()["descuento"] != null &&
              doc.data()["descuento"] != 0 &&
              doc.data()["descuento"] != "0"
                ? "1"
                : "0",
          };
          this.presentaciones = [];
          if (
            doc.data()["presentaciones"] != undefined &&
            doc.data()["presentaciones"] != ""
          ) {
            let p = doc.data()["presentaciones"].split("\n");
            p.forEach((pre) => {
              if (pre != "") {
                this.presentaciones.push(pre);
                this.hasPrices = pre.split(";").length > 1;
              }
            });
            this.presentacion = this.presentaciones[0];
          }
        } else if (doc.data()["estado"] == true) {
          this.items.push({
            key: doc.id,
            nombre: doc.data()["nombre"],
            categoria: doc.data()["categoria"],
            fotos: doc.data()["fotos"],
            precio: doc.data()["precio"].replaceAll(",", "."),
            descuento: doc.data()["descuento"].replaceAll(",", "."),
            oferta:
              doc.data()["descuento"] != null &&
              doc.data()["descuento"] != 0 &&
              doc.data()["descuento"] != "0"
                ? "1"
                : "0",
          });
        }
      });
      this.items = this.items
        .filter((e) => {
          let tiene = false;
          e.categoria.forEach((cat) => {
            if (!tiene) {
              tiene = this.producto.categoria.includes(cat);
            }
          });
          if (this.producto.categoria.length === 0) {
            tiene = true;
          }
          return tiene;
        })
        .slice(0, 3);
    });
  },
  methods: {
    changeQ(change, limit) {
      console.log("entre");
      if (change == -1 && this.carrito_new.cantidad == limit) {
        return;
      }
      this.carrito_new.cantidad += change;
    },
    addProducto() {
      this.carrito_new.nombre = this.producto.nombre;
      if (this.presentaciones.length !== 0) {
        this.carrito_new.presentacion = this.presentacion.split(";")[0];
      } else {
        this.carrito_new.presentacion = "N/A";
      }
      if (this.hasPrices) {
        this.carrito_new.precio = this.presentacion
          .split(";")[1]
          .replaceAll(",", ".");
      } else {
        this.carrito_new.precio =
          this.producto.oferta == "1"
            ? this.producto.descuento.replaceAll(",", ".")
            : this.producto.precio.replaceAll(",", ".");
      }

      let tipo = "productos";
      let all = JSON.parse(window.$cookies.get(tipo));
      if (all == null) {
        all = [this.carrito_new];
      } else {
        all.push(this.carrito_new);
      }
      window.$cookies.set(tipo, JSON.stringify(all));
      this.$parent.$parent.$parent.updateCarrito();
      this.$parent.$parent.$parent.cambiaTab({
        nombre: "Tienda",
        ruta: "/Tienda/all",
      });
    },
    cambiaProducto(ruta) {
      this.$parent.$parent.$parent.cambiaTab({
        nombre: "Tienda",
        ruta: ruta,
      });
      this.$router.go();
    },
  },
};
</script>
