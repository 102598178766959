var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"Tienda",staticClass:"centrar"},[_c('div',{staticClass:"centrar",staticStyle:{"padding":"0px","width":"100%","margin-bottom":"20px"}},[_c('v-img',{staticStyle:{"width":"100vw"},attrs:{"src":require("../assets/banner.jpg"),"height":"300"}},[(_vm.isMobile)?_c('div',{staticClass:"centrar",staticStyle:{"width":"100%","height":"100%","align-items":"flex-start","padding-left":"10px"}},[_c('img',{staticStyle:{"width":"35%","max-width":"200px"},attrs:{"src":require("../assets/logo_white.png")}})]):_vm._e()])],1),_c('span',{staticStyle:{"margin-bottom":"10px"}},[_vm._v("Filtrar Por Categorías:")]),_c('div',{staticStyle:{"max-width":"100%"}},[_c('v-slide-group',{attrs:{"show-arrows":"","multiple":"","center-active":""},model:{value:(_vm.selectedItems),callback:function ($$v) {_vm.selectedItems=$$v},expression:"selectedItems"}},_vm._l((_vm.categorias),function(cat,i){return _c('v-slide-item',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"input-value":active,"active-class":"light-green darken-4 white--text","depressed":"","rounded":"","value":cat,"small":!_vm.isMobile,"x-small":_vm.isMobile,"height":"30px"},on:{"click":function () {
              toggle();
              _vm.llenaCats();
            }}},[_vm._v(" "+_vm._s(cat.nombre)+" ")])]}}],null,true)})}),1)],1),_c('v-container',{staticStyle:{"width":"100%","background-color":"rgb(245,245,245)","padding":"20px","margin-top":"20px"},attrs:{"fluid":""}},[_c('v-data-iterator',{attrs:{"items":_vm.items.filter(function (e) {
          var tiene = false;
          e.categoria.forEach(function (cat) {
            if (!tiene) {
              tiene = _vm.selectedCats.includes(cat);
            }
          });
          if (_vm.selectedCats.length === 0) {
            tiene = true;
          }
          return tiene;
        }),"items-per-page":_vm.itemsPerPage,"page":_vm.page,"search":_vm.$parent.$parent.$parent.buscar,"sort-by":_vm.sortBy.toLowerCase(),"sort-desc":_vm.sortDesc,"hide-default-footer":""},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([(false)?{key:"header",fn:function(){return [_c('v-toolbar',{staticClass:"mb-4",attrs:{"dark":"","color":"grey darken-3"}},[[(!_vm.isMobile)?_c('span',{staticStyle:{"margin-right":"10px"}},[_vm._v("Ordenar Por:")]):_vm._e(),_c('v-select',{staticStyle:{"max-width":"175px"},attrs:{"flat":"","color":_vm.colors[0],"solo-inverted":"","hide-details":"","items":_vm.keys,"prepend-inner-icon":"mdi-sort","label":"Ordenar Por","item-color":_vm.colors[0]},model:{value:(_vm.sortBy),callback:function ($$v) {_vm.sortBy=$$v},expression:"sortBy"}}),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.sortDesc),callback:function ($$v) {_vm.sortDesc=$$v},expression:"sortDesc"}},[_c('v-btn',{attrs:{"depressed":"","color":_vm.colors[0],"value":false}},[(_vm.isMobile)?_c('v-icon',[_vm._v("mdi-arrow-up")]):_c('span',[_vm._v("Ascendente")])],1),_c('v-btn',{attrs:{"depressed":"","color":_vm.colors[0],"value":true}},[(_vm.isMobile)?_c('v-icon',[_vm._v("mdi-arrow-down")]):_c('span',[_vm._v("Descendente")])],1)],1)]],2)]},proxy:true}:null,{key:"default",fn:function(props){return [_c('v-row',_vm._l((props.items.filter(function (e) {
              var tiene = false;
              e.categoria.forEach(function (cat) {
                if (!tiene) {
                  tiene = _vm.selectedCats.includes(cat);
                }
              });
              if (_vm.selectedCats.length === 0) {
                tiene = true;
              }
              return tiene;
            })),function(item){return _c('v-col',{key:item.nombre,attrs:{"cols":"12","sm":"6","md":"4","lg":"3"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var hover = ref.hover;
return [_c('v-card',{class:{ 'on-hover': hover, 'opaco-producto': !hover },attrs:{"elevation":hover ? 7 : 1.5},on:{"click":function($event){return _vm.$parent.$parent.$parent.cambiaTab({
                    nombre: 'Tienda',
                    ruta: '/Tienda/all/' + item.key,
                  })}}},[_c('v-card-title',{staticClass:"subheading font-weight-bold",staticStyle:{"height":"360px"}},[_c('v-carousel',{attrs:{"cycle":"","height":"300","hide-delimiter-background":"","show-arrows-on-hover":""}},_vm._l((item.fotos.split('*')),function(foto,i){return _c('v-carousel-item',{key:i},[_c('v-sheet',{attrs:{"width":"100%","color":"transparent"}},[_c('v-row',{staticClass:"fill-width",staticStyle:{"height":"300px"},attrs:{"align":"center","justify":"center"}},[_c('img',{staticStyle:{"max-height":"100%"},attrs:{"src":foto,"alt":'foto_' + i}})])],1)],1)}),1),_c('span',{staticStyle:{"font-size":"17.5px","word-break":"keep-all"}},[_vm._v(_vm._s(item.nombre))])],1),_c('div',{staticStyle:{"width":"100%","display":"flex","padding-right":"20px","padding-bottom":"20px"}},[_c('div',{staticClass:"text-right",staticStyle:{"width":"100%","font-size":"28px","word-break":"keep-all","display":"flex","flex-direction":"column"}},[_c('span',{staticStyle:{"font-size":"12px"}},[_vm._v("PRECIO")]),_c('div',{staticStyle:{"font-weight":"bold"}},[(item.oferta == '1')?_c('span',{style:('font-size:18px;text-decoration:line-through;font-weight:normal;flex-direction:row;text-decoration-color:' +
                            _vm.colors[0])},[_vm._v("$"+_vm._s(item.precio.split("-").length !== 1 ? parseFloat(item.precio.split("-")[0]).toFixed( 2 ) + "-$" + parseFloat(item.precio.split("-")[1]).toFixed(2) : parseFloat(item.precio).toFixed(2)))]):_vm._e(),_c('span',{staticStyle:{"font-size":"23px"}},[_vm._v(" $"+_vm._s(item.oferta == "1" ? item.descuento.split("-").length !== 1 ? parseFloat( item.descuento.split("-")[0] ).toFixed(2) + "-$" + parseFloat( item.descuento.split("-")[1] ).toFixed(2) : parseFloat(item.descuento).toFixed(2) : item.precio.split("-").length !== 1 ? parseFloat(item.precio.split("-")[0]).toFixed( 2 ) + "-$" + parseFloat(item.precio.split("-")[1]).toFixed(2) : parseFloat(item.precio).toFixed(2)))])])])])],1)]}}],null,true)})],1)}),1)]}},{key:"footer",fn:function(){return [_c('v-row',{staticClass:"mt-2",attrs:{"align":"center","justify":"center"}},[_c('span',{staticClass:"grey--text"},[_vm._v("Productos Por Página")]),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
                            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"dark":"","text":"","color":_vm.colors[0]}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}])},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-title',[_vm._v(_vm._s(number))])],1)}),1)],1),_c('v-spacer'),_c('span',{staticClass:"mr-4\n          grey--text"},[_vm._v(" Página "+_vm._s(_vm.page)+" de "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-btn',{staticClass:"mr-1",attrs:{"fab":"","dark":"","color":_vm.colors[0],"small":""},on:{"click":_vm.formerPage}},[_c('v-icon',[_vm._v("mdi-chevron-left")])],1),_c('v-btn',{staticClass:"ml-1",attrs:{"fab":"","dark":"","color":_vm.colors[0],"small":""},on:{"click":_vm.nextPage}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }